<template>
  <default-layout no-auth>
    <section>
      <h2 class="section-title"><span class="material-icons">person</span>アカウント登録</h2>
      <form>
        <template v-if="tokenError">
          <div class="danger">{{tokenError}}</div>
        </template>
        <template v-else-if="updated">
          <p>アカウント登録が完了しました。</p>
          <p><a @click="$move('/')">ログイン画面</a>からログインしてください。</p>
        </template>
        <template v-else>
          <p class="pt-2 pl-1 pb-1" v-if="email">
            メールアドレス {{email}} でテスト管理者としてアカウント登録します。<br>
            お名前とパスワードを入力して「登録する」ボタンを押してください。
          </p>
          <div class="form-inner">
            <label>お名前</label>
            <input-text placeholder="" v-model="form.name" :error="$error('name')"/>
          </div>
          <div class="form-inner">
            <label>パスワード</label>
            <input-password autocomplete="new-password" placeholder="" v-model="form.newPassword" :error="$error('newPassword')"/>
            <p>英字・数字をそれぞれ１文字以上含め、8文字以上入力してください。</p>
          </div>
          <div class="form-inner">
            <label>パスワード（確認用）</label>
            <input-password autocomplete="new-password" placeholder="" v-model="form.newPasswordConfirm" :error="$error('newPasswordConfirm')"/>
            <p class="c-form-ex__text">上のパスワードと同じ内容を入力してください。</p>
          </div>
        </template>
        <div class="p-2" v-if="!tokenError && !updated">
          <button v-button:save @click="update"></button>
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from "../_resource";
import InputText from "@/calico-vue/form/components/InputText";

export default {
  components: {InputText},
  data () {
    return {
      form: {
        token: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      email: '',
      tokenError: '',
      updated: false,
      error: {}
    };
  },
  created() {
    this.form.token = window.location.hash.substring(1);
    Service.checkToken(this.form.token)
        .then(data => {
          console.log(data);
          this.email = data.email;
        })
        .catch(e => {
          this.tokenError = e.message;
          return false;
        });
  },
  methods: {
    update () {
      Service.updateWithName(this.form)
          .then(() => this.updated = true)
          .catch(e => this.error = e);
    }
  }
}
</script>
